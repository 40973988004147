//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================
$primary: #0951f1 !default;
$black: #000000;
$box-shadow: 0 4px 20px 1px rgb(0 0 0 / 17%);
$dark: #272727;
$gray-400: #383838 !default;
$body-color: #424242 !default;
$body-bg: #f0f3f9 !default;
