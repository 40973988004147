/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import './font/stylesheet.css';
@import './variables/variables';
// USAGE
body {
  font-family: 'Visby CF', Helvetica, Arial, serif !important;
}

.blur-effect {
  filter: blur(4px);
}

.modal {
	background: #0000008a;
}

.shortcut-buttons-flatpickr-button {
	margin: 4px;
    border: 0;
    padding-inline: 9px;
    font-size: 13px;
    font-weight: 500;
	background: $primary;
    color: white;
	border-radius: 3px;
	padding-block: 5px;
}

.shortcut-buttons-flatpickr-button:hover {
	box-shadow: 0px 3px 5px $primary;
	background: $primary;
	// font-size: 14px;
}

.shortcut-buttons-flatpickr-buttons {
	display: flex;
    justify-content: flex-end;
	margin-top: 8px;
	margin-right: 5px;
	margin-bottom: 5px;
}

.filter-blur {
	filter:blur(1px);
}

.custom-brand-icon{
	background: $primary;
    padding: 8px;
    border-radius: 5px;
	box-shadow: $box-shadow;
}

.react-dataTable .rdt_TableBody .rdt_TableRow {
	color: $black;
}

.navigation{
	font-size: 1rem;
}

.pr-1{
	padding-right: 1rem;
}
.vc-rating{
	font-size: 16px;
}
.pointer{
	cursor: pointer;
}
.italic{
	font-style: italic;
}
.primary{
color: #0951f1;
}
.ml-40{
	margin-left: 40px;
}
.ml-12{
	margin-left: 12px;
}
.mr-40{
	margin-right: 40px;
}
.pr-40{
	padding-right: 40px;
}
.pl-40{
 padding-left: 40px;
}
.pb-10{
	padding-bottom: 10px;
}
.p-5px{
 padding: 5px;	
}
.p-10px{
	padding:10px
}
.bR-5px{
	border-radius: 5px;
}

/* Calender */
.fc-day-today{
  background-color:  rgba(51 , 48 , 60, 0.04) !important
}
.fc-event-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rdt_TableCell span.d-block.fw-bold.text-truncate {
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    width: 89% !important;
}
.spinner-wrap {
	width: 100%;
	text-align: center;
}

.copybtn-wrap {
    width: 25px;
    height: 25px;
    text-align: center;
}



/* Styling for print Hangtag */
@media print {
	html, body {
    height: auto;
	// page-break-inside: avoid;
	// page-break-before: avoid;
	// page-break-after: avoid;
	  overflow: hidden;
	// height: 4in;
	// width: 2.5in;
	}
}


@page {
	margin: 0;
}

.color-black{
	color:#424242;
}

.fs-Xlarge{
	font-size: -webkit-xxx-large
}

.ff-fantasy{
	font-family: fantasy;
}
.fs-8px{
	font-size: 8px;
}
.fs-7px{
	font-size: 7px;
}

.fw-800{
	font-weight: 800;
}
.color-lightgrey{
	color: lightgrey;
}
.react-dataTable.dealer-table [data-testid*="expander-button-"][disabled] {
    display: none !important;
}
.c-color-box{
	padding: 0;
}
